/**
 * Ids generated by GraphQL endpoints are usually in the format
 * gid://gitlab/Environments/123. This method extracts Id number
 * from the Id path
 *
 * @param {String} gid GraphQL global ID
 * @returns {Number}
 */
export const getIdFromGraphQLId = (gid = '') =>
  parseInt((gid || '').replace(/gid:\/\/gitlab\/.*\//g, ''), 10) || null;

export default {};
